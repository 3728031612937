import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { useStyles } from "./EntitiesAudit.style";
import clsx from "clsx";
import { AUDIT_TYPES } from "../../../constants";

import { useSelector } from "react-redux";
import { FindEntity } from "../../../state/slices/entities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import SquareChip from "../../SquareChip"
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const EntitiesAudit = ({ onDrawer, username, action, date, payload , ...props}) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;
  const useCoreEntitySlice = useCoreEntityContext();

  const entity = useSelector((state) => {   //not this only works for Entities / aka settings
    //they either edited the demographics or the settings
    console.log("WHAT",_payload);
    let _id =  ('facility' in _payload)                     //this is EXACTLY WHY i said for entity endpoints to be /entity -- not /facility /device /etc
            ? _payload?.facility?.FacilityID                //now we have to deal with a billion shaped payloads for the exact same object
            : ('setting' in _payload)                       //this is a giant mess
                ? _payload?.setting?.EntityID
                : ('settings' in _payload)                  //if it says facility, or has 'setting' (facility setting change), or says 'device', or says 'settings' (device setting)
                    ? _payload?.settings[0]?.EntityID       //i mean -- seriously
                    : _payload?.device?.DeviceID            //also removes entirely the original architecture of being able to json diff them against previous for 'what changed'
    ;
    const _entity = useCoreEntitySlice ? selectEntityById(state,_id) : FindEntity(state.entities?.EntityList ?? [],  _id) ;
    console.log("_entity", _entity);

    return {
      EntityID: _entity?.entityid ?? null,
      Name    : _entity?.name ?? null,
      Type    : _entity?.type ?? null,
      Setting : ('settings' in _payload) ? _payload?.settings[0]?.SettingName?.replace("setting.","") : ('setting' in _payload) ? _payload?.setting?.SettingName :  null,
      Value   : ('settings' in _payload) ? _payload?.settings[0]?.SettingValue : ('setting' in _payload) ? _payload?.setting?.SettingName : null,
    }
  });
  const getActionLabel = () => {
    switch (action) {
      case "add":
        return "Created";
      case "edit":
        return "Edited";
      case "delete":
        return "Deleted";
      default:
        return action;
    }
  };  

  const handleDrawerClick = () => { //callback to top level to render drawer about this specific entry
    onDrawer("callback " + date.toString());
  }

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action"  className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
          {entity?.Name !=null && (
            <div><span className={classes.typeName}>{entity?.Type}: </span><span className={clsx([classes.deviceName])}>{entity?.Name}</span></div>
          )}
        </td>
        <td data-column="Details"  className={clsx([classes.tdc, classes.details])}>
          {1==2 && (
            <FontAwesomeIcon onClick={handleDrawerClick} size="lg" className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

    </>
  );
};

export default EntitiesAudit;
