import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Table from "@material-ui/core/Table";
import {
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
  TableContainer,
  AccordionDetails,
  Grid,
  Link,
  IconButton,
  Box
} from "@material-ui/core";
import Title from "../../Title";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { ALERT_SEVERITY } from "../../../constants/index";
import TablePaginationAction from "../../TablePaginationActions";
import EnhancedTableHead from "../../EnchancedTableHead/EnhancedTableHead";
import { useHistory } from "react-router-dom";
import useHasPermissions from "../../../hooks/useHasPermissions";
import moment from "moment-timezone";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan as clearIcon,
  faCommentAltCheck as claimIcon,
  faCommentAltMinus as unclaimIcon
} from "@fortawesome/pro-regular-svg-icons";
import {selectContextEntity} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";


const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, comparator) => {
  let unassignedList = [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    if (a[0].assignedAt == null) {
      a[0].assignedAt = "000";
      unassignedList.push(a[0]);
    }

    if (b[0].assignedAt == null) {
      b[0].assignedAt = "000";
      unassignedList.push(b[0]);
    }
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (unassignedList != undefined && unassignedList.length > 0) {
    unassignedList.forEach(row => {
      row.assignedAt = null;
    });
  }

  return stabilizedThis.map(el => el[0]);
};

const AlertTable = ({
  alerts,
  headerRow,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleRequestSort,
  handleUnclaim,
  handleClaim,
  handleClear,
  handleChangePage,
  handleChangeRowsPerPage,
  isAdmin,
  showFacility
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const useCoreEntitySlice = useCoreEntityContext();

  const ReportPermission = hasPermissions(["AlarmsReport"]);
  const ClearPermission = hasPermissions(["dashboard.alerts.Clear"]);
  const ClaimPermission = hasPermissions(["dashboard.alerts.Claim"]);

  const theme = useTheme();
  const smallMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xlMatch = useMediaQuery(theme.breakpoints.down("md"));

  const currentUserID = useSelector(state => state.user?.UserID);

  const handleReportClick = () => {
    history.push(`/reports/AlarmsReport`);
  };

  const getKey = value => {
    for (let key in ALERT_SEVERITY)
      if (ALERT_SEVERITY[key] === value) {
        return (
          <div className={clsx([classes.alert, key.toLowerCase()])}>{key}</div>
        ); //class="alert medium"
      }

    return <>key not found</>;
  };
  const timeZone = useSelector(
    (state) => {
      const timezone = useCoreEntitySlice
        ? selectContextEntity(state)?.details?.timezone
        : state.entities.Context?.details?.timezone;
      return timezone ? timezone : moment.tz.guess();
    },
    shallowEqual
  );

  return (
    <>
      <Grid container  className={classes.alertscroll}>
        <Grid item xs={12}>
          <Table size="small" className={classes.table} data-testid="AlertTable">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={alerts.length}
              headerRow={headerRow}
            />
            <TableBody className={clsx("alerts-table-body")}>
              {(rowsPerPage > 0
                ? stableSort(alerts, getComparator(order, orderBy)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : stableSort(alerts, getComparator(order, orderBy))
              ).map(row => (
                <TableRow
                  key={row.alertID}
                  data-alert-id={row.alertID}
									data-testid="AlertRow"
                  className={clsx(`${row.alertID}_alertRow`)}
                >
                  <TableCell>
                    <span
                      className={clsx("alert-name")}
                      data-value={row.eventName}
                    >
                      {row.eventName}
                    </span>
                    {smallMatch && (
                      <div>
                        {showFacility && (
                          <div style={{ fontSize: "smaller", color: "silver" }}>
                            {row.nearestFacilityName}
                          </div>
                        )}
                        <div style={{ fontSize: "smaller", color: "silver" }}>
                          {row.name}
                        </div>
                      </div>
                    )}
                  </TableCell>
                  {(!smallMatch && showFacility) && (
                    <TableCell>
                      <span
                        className={clsx("facility-name")}
                        data-value={row.nearestFacilityName}
                      >
                        {row.nearestFacilityName}
                      </span>
                    </TableCell>
                  )}
                  {!smallMatch && (
                    <TableCell>
                      <span
                        className={clsx("device-name")}
                        data-value={row.name}
                      >
                        {row.name}
                      </span>
                    </TableCell>
                  )}
                  <TableCell>
                    <span
                      className={clsx("severity")}
                      data-value={row.severityID}
                    >
                      {getKey(row.severityID)}
                    </span>
                    {smallMatch && (
                      <div style={{ fontSize: "smaller" }}>
                        {moment
                          .utc(row.createdAt)
                          .tz(timeZone)
                          .format("MM/DD/YYYY hh:mm:ss A")
                          .toString()}
                      </div>
                    )}
                  </TableCell>
                  {!smallMatch && (
                    <TableCell>
                      <span
                        className={clsx("alert-time")}
                        data-value={moment
                          .utc(row.createdAt)
                          .tz(timeZone)
                          .format("MM/DD/YYYY hh:mm:ss A")
                          .toString()}
                      >
                        {moment
                          .utc(row.createdAt)
                          .tz(timeZone)
                          .format("MM/DD/YYYY hh:mm:ss A")
                          .toString()}
                      </span>
                    </TableCell>
                  )}
                  {!smallMatch && (
                    <TableCell>
                      <span
                        className={clsx("assigned-to")}
                        data-value={
                          row.status == "NotYetClaimed"
                            ? (row.firstName = "Unclaimed")
                            : row.firstName + " " + row.lastName
                        }
                      >
                        {row.status == "NotYetClaimed"
                          ? (row.firstName = "Unclaimed")
                          : row.firstName + " " + row.lastName}
                      </span>
                    </TableCell>
                  )}
                    {smallMatch && (
                         <TableCell>
                      <div
                        className={clsx([
                          classes.claim,
                          currentUserID == row.assignedTo ? "yours" : "",
                          row.status == "NotYetClaimed" ? "unclaimed" : "",
                          `${row.alertID}-assigned-to`
                        ])}
                      >
                        {row.status == "NotYetClaimed"
                          ? (row.firstName = "Unclaimed")
                          : row.firstName + " " + row.lastName}
                      </div>
                     </TableCell>
                    )}
                     <TableCell	align="right">
                    <Box
                      sx={{ m: 0 }}
                      style={{ whiteSpace: "nowrap" }}
                      className={classes.buttonWrapper}
                    >
                      {ClaimPermission && (
                        <>
                          {xlMatch ? (
                            <IconButton
                              title={
                                row.status === "NotYetClaimed"
                                  ? "Claim This Alert"
                                  : "Unclaim This Alert"
                              }
                              color="primary"
                              component="span"
                              onClick={() =>
                                row.status === "NotYetClaimed"
                                  ? handleClaim(row)
                                  : handleUnclaim(row)
                              }
                              className={clsx(["alerts-button", "claim"])}
                              data-id={row.alertID}
                            >
                              {row.status === "NotYetClaimed" ? (
                                <FontAwesomeIcon
                                  icon={claimIcon}
                                ></FontAwesomeIcon>
                              ) : (
                                <FontAwesomeIcon
                                  icon={unclaimIcon}
                                ></FontAwesomeIcon>
                              )}
                            </IconButton>
                          ) : (
                            <Button
                              startIcon={
                                row.status === "NotYetClaimed" ? (
                                  <FontAwesomeIcon
                                    icon={claimIcon}
                                  ></FontAwesomeIcon>
                                ) : (
                                  <FontAwesomeIcon
                                    icon={unclaimIcon}
                                  ></FontAwesomeIcon>
                                )
                              }
                              className={clsx([
                                classes.claimButtons,
                                "alerts-button",
                                "claim"
                              ])}
                              size="small"
                              data-id={row.alertID}
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                row.status === "NotYetClaimed"
                                  ? handleClaim(row)
                                  : handleUnclaim(row)
                              }
                            >
                              {row.status === "NotYetClaimed" ? (
                                <span>Claim</span>
                              ) : (
                                <span>Unclaim</span>
                              )}
                            </Button>
                          )}
                        </>
                      )}
                      {ClearPermission && (
                        <>
                          {xlMatch ? (
                            <IconButton
                              onClick={() => handleClear(row)}
                              title="Clear This Alert"
                              color="secondary"
                              aria-label="clear claim"
                              component="span"
                              data-id={row.alertID}
                              className={clsx([
                                "alerts-button",
                                "clear",
                                smallMatch ? "small" : "normal"
                              ])}
                            >
                              <FontAwesomeIcon
                                icon={clearIcon}
                              ></FontAwesomeIcon>
                            </IconButton>
                          ) : (
                            <Button
                              startIcon={
                                <FontAwesomeIcon
                                  icon={clearIcon}
                                ></FontAwesomeIcon>
                              }
                              className={clsx([
                                "alerts-button",
                                "clear",
                                smallMatch ? "small" : "normal"
                              ])}
                              size="small"
                              data-id={row.alertID}
                              color="secondary"
                              variant="outlined"
                              onClick={() => handleClear(row)}
                            >
                              {!smallMatch && <span>Clear</span>}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid container>
          <Grid item xs={12} className={classes.alignContentRight}>
            <TablePagination
              labelRowsPerPage={<span>Rows</span>}
              backButtonProps={{ color: "primary" }}
              component="div"
              rowsPerPageOptions={[5, 10, 15]}
              className={clsx("table-pagination")}
              count={alerts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationAction}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AlertTable;
