import React from "react";
import { useSelector } from "react-redux";
import { FindEntity } from "../../../state/slices/entities";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import SquareChip from "../../SquareChip"
import { useStyles } from "./CallCenter.style";
import {selectEntityById} from "../../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const CallCenterActivityAudit = ({            //the data coming from the call center
  onDrawer,                                   //is different , and mostly useless for detail
  username,                                   //is it because of the shapes?  more /x/x/ID/x stuff?
  action,                                     //half of them don't write the deviceID to the payload
  date,                                       //so how the heck are we supposed to show THE MOST IMPORTANT
  payload,                                    //bit of information?
  ...props
}) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload);
  const useCoreEntitySlice = useCoreEntityContext();

  const entity = useSelector(state => {
    const targetId = _payload?.request?.DeviceID ?? _payload?.DeviceID;

    if(useCoreEntitySlice) return selectEntityById(state, targetId);

    return FindEntity(
      state.entities?.EntityList ?? [],
        targetId
    );
  });

  const getActionLabel = () => {
    switch (action) {
      case "edit":
        if (_payload?.activity?.hasOwnProperty("Resolution")) {
          return getResolution() ? "Ended" : "Answered";
        } else {
          return "Updated";
        }
      case "add":
        if (payload?.callid) {
          return "Created";
        } else {
          return "Requested";
        }
      case "delete":
        return "Cancelled";
      default:
        return action;
    }
  };

  const getResolution = () => {
    return false;
    //in the future -- this should be the pop out drawer (the clickable "i" icon)
    return _payload?.activity?.Resolution;
  };

  const handleDetailsClick = () => {
    onDrawer("call center " + date.toString());
  }

  return (
    <>
      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}>
          <SquareChip size="small" color="primary" text="Call Center"/>
        </td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
          <div className={clsx([classes.deviceName])}>{entity?.name}</div>
        </td>
        <td data-column="Details"  className={clsx([classes.tdc,classes.details])}>
          {getResolution()}
          {1==2 && (
          <FontAwesomeIcon size="lg" onClick={handleDetailsClick} className={classes.detailIcon} alt={JSON.stringify(_payload)} title={JSON.stringify(_payload)} icon={faInfoCircle}></FontAwesomeIcon>
          )}
        </td>
      </tr>

      
      {/* <TableCell className={clsx([""])}>
        {date + "\n" + username}
      </TableCell>
      <TableCell className={clsx([""])}>{getActionLabel()}</TableCell>
      <TableCell className={clsx([""])}>{entity?.name}</TableCell>
      <TableCell className={clsx([""])}>{getResolution()}</TableCell> */}
    </>
  );
};

export default CallCenterActivityAudit;
