import React, { useState, useEffect, useCallback } from "react";
import SearchBar from "../../../SearchBar/index";
import { useStyles } from "./styles";
import clsx from "clsx";
import UserService from "../../../../services/UserService";
import apiClient from "../../../../auth/apiClient";
import AdminUserCard from "./AdminUserCard/index";
import { Grid, FormControlLabel, Switch, Typography, Button,  Dialog,
  DialogContent, } from "@material-ui/core";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import _ from "lodash";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import useUserContext from "../../../../hooks/useUserContext";
import { onFilterChange } from "../../../../reducers/users/userReducer";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import AddAdminUser from "./AddAdminUser/AddAdminUser";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectAllEntities} from "../../../../state/slices/CoreEntity";

const userService = new UserService(apiClient);

function getRowsByHeight(height) {
    return Math.round(height / 130)-3;
}

function getTiledLimit(height, width) {
  let cardsPerRow = 4;

  //xs and sm grid size
  if(width >= 0 && width < 960)
    cardsPerRow = 1
  
  //md grid size
  if(width >= 960 && width < 1280)
    cardsPerRow = 2

  //lg grid size
  if(width >= 1280 && width < 1920)
    cardsPerRow = 4

  //xl grid size
  if(width >= 1920)
    cardsPerRow = 4

  let num = (Math.round(height/ 130) * cardsPerRow)-12
  
  return num;
}

const AdminGroupForm = ({ entityID }) => {
  const classes = useStyles();
  const currentUserId = useSelector((state) => state.user.UserID);
  const [userList, setUserList] = useState({
    totalCount: 0,
    collection: [],
  });
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [tiled, setTiled] = useState(false);
  const { height, width } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const { userReducer } = useUserContext();
  const [searchedTerm, setSearchedTerm] = useState("")
  const enqueueSnackbar = useEnqueueSnackbar();
  const [userData, userDispatch] = userReducer;
  let pages = Math.ceil(userList?.totalCount / itemLimit);
  const [amiAdminGroupID, setAmiAdminGroupID] = useState("")
  const [modalOpen, setModalOpen] = useState(false);
  const useCoreEntitySlice = useCoreEntityContext();
  const adminEntityID = useSelector((state) => useCoreEntitySlice ? selectAllEntities(state).find(x => x.name.toLowerCase() == "ami" && x.parententityid == null)?.entityid
      : state?.entities?.EntityListRaw.find(x => x.name.toLowerCase() == "ami" && x.parententityid == null)?.entityid);

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      marginRight: 5,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,

        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const getUsers = useCallback(async (page, limit = itemLimit, searchTerm = "") => {
    try {
      let result = await userService.GetUsersWithTheirAdminGroupStatus(
        limit,
        page ? (page - 1) * limit : 0,
        searchTerm
      );

      setUserList({ ...result?.data });
      setFilteredUserList([ ...result?.data?.collection ]);
    } catch {
      enqueueSnackbar("Failed to get admin users", { variant: "error" });
    }
  });

  const DeleteAdminUser = async (userID) => {
    try {
      await userService.deleteAdminUser(userID);

      let tmp = { ...userList };
      let filteredTmp = [ ...filteredUserList ];

      filteredTmp.splice(filteredTmp.findIndex((x) => x.userId == userID), 1)
      tmp.collection.splice(tmp.collection.findIndex((x) => x.userId == userID), 1);
      tmp.totalCount -= 1;

      refreshList();
    } catch {
      enqueueSnackbar("Failed to delete admin user", { variant: "error" });
    }
  };

  const getAMIAdminGroupID = async () => {
    try {
      let result = await userService.getUserGroups(adminEntityID, 2, 0, 'AMIAdmin');
      if(result.data?.collection?.length == 1) {
        let test = result.data.collection[0].groupID;
        setAmiAdminGroupID( test )
      }
    } catch (error) {
      enqueueSnackbar("Failed to get admin group id", { variant: "error" });
    }
  }

  const getNewLimit = () => {
    if (height) {
      setItemLimit(height > 0 && !tiled ? getRowsByHeight(height) : getTiledLimit(height, width));
    }
  }

  const refreshList = () => {
    getUsers(userData.userPage, itemLimit, userData.userSearchTerm);
  };

  useEffect(() => {
    getUsers(userData.userPage, itemLimit, userData.userSearchTerm);
    if(amiAdminGroupID == "") {
      getAMIAdminGroupID()
    }
  }, [userData.userPage, itemLimit, entityID, userData.userSearchTerm]);

  useEffect(() => {
    getNewLimit();
  }, [height]);

  useEffect(() => {
    getNewLimit();
    userDispatch({
      type: onFilterChange,
      payload: { userSearchTerm: userData.userSearchTerm, userPage: 1 },
    });
  }, [tiled])

  const handleRemove = (userID) => {
    DeleteAdminUser(userID);
  };

  const handlePageChange = (e, page) => {
    userDispatch({ type: onFilterChange, payload: { userPage: page } });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleUserSearch = (term) => {
    setSearchedTerm(term)
  };

  useEffect(() => {
    userDispatch({
      type: onFilterChange,
      payload: { userSearchTerm: searchedTerm?.trimLeft()?.trimRight(), userPage: 1 },
    });
  }, [searchedTerm])

  const findUserByEmail = async (email) => {
    let userId = null;
    try {
      var result = await userService.getUserByEmail(email);

      if (result.status === 200) {
        userId = result?.data;
      }
    } catch (ex) {
      if (!(ex.response?.status === 404 && ex.response?.data === "User Not Found")) {
        enqueueSnackbar("Unable to search existing user", { variant: "error", tag: "ErroSearchingExistingUser" });
      }
    }
    return userId;
  }

  const addUser = async (userInfo) => {
    try {
      await userService.createUser(userInfo);
    } catch (error) {
      enqueueSnackbar("Failed to create admin user", { variant: "error" });
    }
  };

  const addUserToAdminGroup = async (userInfo) => {
    try {
      await userService.assignUserGroups(userInfo);
    } catch (ex) {
      enqueueSnackbar("Failed to update admin user", { variant: "error" });
    }
  }

  async function checkUser(userInfo) {
    try {
      let userShape = {
        email: userInfo.email,
        firstName: "",
        lastName: "",
        entities: [{
          scope: adminEntityID,
          groups: [{
            groupID: amiAdminGroupID
          }]
        }]
      }

      var existingUserID = await findUserByEmail(userInfo.email);
      if(existingUserID) {
        var result = await userService.getUser(existingUserID);
        userShape.UserId = existingUserID;
        userShape.firstName = result.data.firstName;
        userShape.lastName = result.data.lastName;

        await addUserToAdminGroup(userShape);
      } else {
        await addUser(userShape);
      }
    } catch {
      return;
    }
    refreshList();
    toggleModal(false)
  }

  return (
    <>
      <Grid container className={clsx(["Admin-container"], classes.gridContainer)}>
        <Grid item xs={12} md={12} lg={10} xl={10}>
          <Grid item xs={12} md={12} lg={12} xl={12} className={ classes.searchBarContainer }>
            <Typography variant="h3" color="Primary" className={clsx(["title"])}>
              Admin Users
            </Typography>
            <Grid item xs={12} md={12} lg={12} xl={12} style={{ float: "right" }}>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={tiled}
                    color="primary"
                    onChange={() => {
                      setTiled(!tiled);
                    }}
                    className={clsx(["tile", "button", "switch"])}
                  />
                }
                label="Tile"
              />
              <Button color="primary" size="small" variant="contained" onClick={toggleModal} className={clsx(["add-user", "button"])}>Add User</Button>
            </Grid>
            <Grid container>
            <Grid item xs={12} md={pages > 6 ? 6 : 7} lg={pages > 6 ? 7 : 8} xl={pages > 6 ? 8 : 9}>
              <SearchBar className={clsx(["search-bar"], classes.searchBar)} onChange={handleUserSearch} defaultSearchTerm={searchedTerm} value={userData.userSearchTerm} />
            </Grid>
            <Grid item xs={12} md={pages > 6 ? 6 : 5} lg={pages > 6 ? 5 : 4} xl={pages > 6 ? 4 : 3} className={ classes.paginationAlign }>
              <Pagination
                className={clsx("pagination")}
                page={userData.userPage ?? 1}
                onChange={handlePageChange}
                count={Math.ceil(userList.totalCount / itemLimit)}
                color="primary"
                shape="rounded"
                size="small"
                showFirstButton
                showLastButton
              />
            </Grid>
            </Grid>

          </Grid>
        <Grid container >
        {filteredUserList?.map((userInfo) => {
          return (
            <Grid
              key={"grid"+userInfo.userId}
              item
              xl={tiled ? 2 : 12}
              lg={tiled ? 3 : 12}
              md={tiled ? 6 : 12}
              sm={tiled ? 12 : 12}
              xs={tiled ? 12 : 12}
            >
              <AdminUserCard
                key={userInfo.userId}
                userInfo={userInfo}
                tiled={tiled}
                handleRemove={handleRemove}
                currentUserID={currentUserId}
              />
            </Grid>
          );
        })}
        </Grid>
      </Grid>
      </Grid>
      <Dialog
        className={classes.historyDialog}
        maxWidth="sm"
        fullWidth
        open={modalOpen}
        onClose={toggleModal}
      >
        <DialogContent>
          <AddAdminUser toggleModal={toggleModal} createUser={checkUser} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminGroupForm;
