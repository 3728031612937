import React, { useState } from "react";
import { useSelector } from "react-redux";
import SelectableChip from "../../../SelectableChip";
import { Grid, Button, TextField, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./accessHolderSearchButtons.style";
import PropTypes from "prop-types";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlags";
import { DEVICE_MODE, ENTITY_TYPE } from "../../../../constants";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectAllEntities} from "../../../../state/slices/CoreEntity";

const AccessHolderSearchButtons = ({ onSearch, loading }) => {
    const classes = useStyles();
    const [category, setCategory] = useState("ContactInfo");
    const [searchTerm, setSearchTerm] = useState("");
    const isHotelIntegrationsEnabled = useFeatureFlag("Hotel Integrations");
    const useCoreEntitySlice = useCoreEntityContext();

    const hotelInterfaceDevices = useSelector((state) =>
        useCoreEntitySlice ? selectAllEntities(state).find((entity) => entity?.typeid === ENTITY_TYPE.Device && entity?.settings?.find((setting) => setting?.name === "devicemode" && setting?.value === DEVICE_MODE.HOTEL_INTERFACE))
            : state?.entities?.EntityListRaw?.find((entity) => entity?.typeid === ENTITY_TYPE.Device && entity?.settings?.find((setting) => setting?.name === "devicemode" && setting?.value === DEVICE_MODE.HOTEL_INTERFACE))
    );

    const setSearch = () => {
      onSearch({
        searchCategory: category,
        searchTerm: searchTerm,
        page: 1
      });
    }

    const getHelperText = () => {
      if (category === "AccountNumber" || category === "Credential") {
        return " ";
      }
      return "minimum 3 characters";
    }

    const isDisabled = () => {
      if (category === "AccountNumber" || category === "Credential") {
        return (loading || !category || !searchTerm);
      }
      return (loading || !category || !searchTerm || searchTerm?.length < 3);
    }

    const getTooltip = () => {
      if (!category || !searchTerm) {
        return "";
      }

      var categoryText = category;
      if (category === "AccountNumber") {
        categoryText = "Account Number";
      } else if (category === "ContactInfo") {
        categoryText = "Contact Info";
      }      

      if (searchTerm.length < 3 && (category === "AccountNumber" || category === "Credential")) {
          return "find " + categoryText + " matching " + searchTerm + " exactly"
      }

      return "find " + categoryText + " containing " + searchTerm 
    }

    const canRenderRoomNumberChip = () => {
      if (isHotelIntegrationsEnabled && hotelInterfaceDevices) {
        return true;
      } else {
        return false;
      }
    }

    return (
      <>
        <Grid container spacing={2} className={clsx("category", classes.accessHolderCategory)}>
          <Grid item xs={12} sm={6} className={classes.chipGrid}>
            <SelectableChip
              text="Account Number"
              className={clsx(["type-option", "accountNumber"])}
              variant="primary"
              onClick={() => setCategory("AccountNumber")}
              selected={category === "AccountNumber"}
            />
            <SelectableChip
              text="Credential"
              className={clsx(["type-option", "credential"])}
              variant="primary"
              onClick={() => setCategory("Credential")}
              selected={category === "Credential"}
            />
             <SelectableChip
              text="Vehicle"
              className={clsx(["type-option", "vehicle"])}
              variant="primary"
              onClick={() => setCategory("Vehicle")}
              selected={category === "Vehicle"}
            />
             <SelectableChip
              text="Contact Info"
              className={clsx(["type-option", "contactInfo"])}
              variant="primary"
              onClick={() => setCategory("ContactInfo")}
              selected={category === "ContactInfo"}
            />
            {canRenderRoomNumberChip() &&
              <SelectableChip
                text="Room Number"
                className={clsx(["type-option", "hotel"])}
                variant="primary"
                onClick={() => setCategory("Hotel")}
                selected={category === "Hotel"}
              />
            }
          </Grid>
          <Grid item xs={12} md={8}>
            <Tooltip title={getTooltip()} arrow placement="top-start">
              <TextField 
                label="Search for..."
                id="search-term"
                data-testid="search-term"
                variant="outlined"
                className={clsx("search-field", classes.searchTerm)}
                helperText={getHelperText()}
                onChange={(e) => { setSearchTerm(e.target.value); }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              type="submit"
              className={clsx("search-btn-submit", classes.submitBtn)}
              variant="contained"
              color="primary"
              data-testid="search-btn"
              onClick={() => setSearch()}
              disabled={isDisabled()}
            >Search</Button>
          </Grid>
        </Grid>
      </>
    );
  };

AccessHolderSearchButtons.defaultProps = {
  onSearch: () => {},
  isLoading: false
};

AccessHolderSearchButtons.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool
};

export default AccessHolderSearchButtons;